.nav-left {
	/*padding: 0 1em;*/
}

.nav-left {
	position: relative;

	.menu_level_1 {
		display: block;

		& > li {
			list-style: none;
			transition: all 0.3s ease;
			-webkit-tap-highlight-color: $webkitHighlightTapColor;


			&:first-child {
				border-top: none;
			}

			&:last-child {
				border-bottom: none;
			}

			& > a {
				display: block;
				color: $textColor;
				padding: 5px 1em;
				position: relative;
				text-decoration: none;
				transition: color 0.3s ease;

				&:after {

					@include fontawesome('\f054') {
						color: #333;
						font-size: 12px;
						margin-top: -7px;
						top: 50%;
						left: auto;
						right: 1em;
						transition: 0.1s ease 0.07s;
					}
				}
			}

			&.subcats_exists {

				&:not(.selected):hover {

					& > a {

						&:after {
							right: 1em;
							transform-origin: left bottom;
							transform: rotate(90deg);
						}
					}
				}
			}

			&.selected {
				// background: $linkColor--hover + 15%;
				cursor: default;
				&:nth-child(1){
					background: linear-gradient(to top, #438DC8 - 30%, #438DC8);
				}
				&:nth-child(2){
					background: linear-gradient(to top, #f6b800 - 30%, #f6b800);
				}
				&:nth-child(3){
					background: linear-gradient(to top, #8870E6 - 30%, #8870E6);
				}
				&:nth-child(4){
					background: linear-gradient(to top, #e7792e - 30%, #e7792e);
				}
				&:nth-child(5){
					background: linear-gradient(to top, #ee60a8 - 30%, #ee60a8);
				}
				&:nth-child(6){
					background: linear-gradient(to top, #73B629 - 30%, #73B629);
				}
				&:nth-child(7){
					background: linear-gradient(to top, #438DC8 - 30%, #438DC8);
				}
				&:nth-child(8){
					background: linear-gradient(to top, #f6b800 - 30%, #f6b800);
				}
				&:nth-child(9){
					background: linear-gradient(to top, #8870E6 - 30%, #8870E6);
				}
				&:nth-child(10){
					background: linear-gradient(to top, #e7792e - 30%, #e7792e);
				}
				&:nth-child(11){
					background: linear-gradient(to top, #ee60a8 - 30%, #ee60a8);
				}
				& > a {
					color: #fff;
					text-decoration: none;
				}

				&.subcats_exists {
					& > a {
						&:after {
							color: #fff;
							transform: rotate(90deg);
						}
					}
				}
			}
		}
	}

	.menu_level_2 {
		background: $white;
		display: block;
		padding: 0;

		& > li {
			list-style: none;

			& > a {
				color: $textColor;
				display: block;
				font-size: 13px;
				padding: 5px 1em;
				text-decoration: none;
			}

			&:hover {
				& > a {
					color: $linkColor--hover;
					text-decoration: underline;
				}
			}

			&.selected {
				& > a {
					color: $linkColor--hover;
					text-decoration: none;
				}
			}
		}
	}

	.menu_level_3 {
		padding: 0.5em 1em 0.75em 2em;

		& > li {
			list-style: disc;
			padding: 0.2em 0;

			& > a {
				color: $textColor;
				display: block;
				text-decoration: none;
			}

			&.selected {
				color: $linkColor;
				& > a {
					color: $linkColor;
				}
				&:hover {
					color: $linkColor;
					& > a {
						color: $linkColor;
					}
				}
			}

			&:hover {
				color: $linkColor--hover;
				transition: color 0.3s ease;
				& > a {
					color: $linkColor--hover;
				}
			}
		}
	}
}


/* ховеры только на десктопах */
.desktop {
	.nav-left {
		.menu_level_1 {
			& > li {
				&.selected{

				}
				&:not(.selected) {
					&:hover {
						// background: $linkColor;
						&:nth-child(1){
							&:hover{
								background: linear-gradient(to top, #438DC8 + 30%, #438DC8 + 60%);
							}
						}
						&:nth-child(2){
							&:hover{
								background: linear-gradient(to top, #f6b800 + 30%, #f6b800 + 60%);
							}
						}
						&:nth-child(3){
							&:hover{
								background: linear-gradient(to top, #8870E6 + 30%, #8870E6 + 60%);
							}
						}
						&:nth-child(4){
							&:hover{
								background: linear-gradient(to top, #e7792e + 30%, #e7792e + 60%);
							}
						}
						&:nth-child(5){
							&:hover{
								background: linear-gradient(to top, #ee60a8 + 30%, #ee60a8 + 60%);
							}
						}
						&:nth-child(6){
							&:hover{
								background: linear-gradient(to top, #73B629 + 30%, #73B629 + 60%);
							}
						}
						&:nth-child(7){
							&:hover{
								background: linear-gradient(to top, #438DC8 + 30%, #438DC8 + 60%);
							}
						}
						&:nth-child(8){
							&:hover{
								background: linear-gradient(to top, #f6b800 + 30%, #f6b800 + 60%);
							}
						}
						&:nth-child(9){
							&:hover{
								background: linear-gradient(to top, #8870E6 + 30%, #8870E6 + 60%);
							}
						}
						&:nth-child(10){
							&:hover{
								background: linear-gradient(to top, #e7792e + 30%, #e7792e + 60%);
							}
						}
						&:nth-child(11){
							&:hover{
								background: linear-gradient(to top, #ee60a8 + 30%, #ee60a8 + 60%);
							}
						}
						& > a {
							color: $white;
							&:after {
								right: 0.8em;
							}
						}
					}
				}
			}
		}
	}
}