@import "vars";
@import "mixins";

/* Импорт sass модулей, по порядку */
/* Неиспользуемые модули надо закомментировать, используемые - раскомментировать */
@import "system/normalize";
@import "system/preloader";
@import "media";               /* Медиа миксины. Как использовать см. внутри */
@import "system/fonts";        /* подгрузка шрифтов */
@import "system/icon";
@import "layout";              /* Основной склелет */
@import "styles";              /* Основные стили */
@import "system/forms";
@import "header/header";
@import "header/headermobile";
@import "slider";
@import "menu/menu_top";
@import "menu/menu_side";
@import "menu/mobile_menu";
@import "system/feedback";
@import "footer";
@import "system/landing";


/* Айшоп  */
@import "ishop/valuta";
@import "ishop/ajaxflowcart";
@import "ishop/product";
@import "ishop/main-catalog";
@import "ishop/catalog";
@import "ishop/category";
@import "ishop/cart";
@import "ishop/filters";
@import "ishop/compare";
@import "ishop/order";
@import "ishop/productrating";
@import "ishop/brands";
/*@import "booking/booking";*/

@import "system/popups";
@import "opros/opros";
@import "search";
@import "photo/photos";
/*@import "qa";*/
@import "messages";
@import "system/modules";
@import "news/news";
@import "news/newsfull";
@import "news/navigation";
@import "reg/reg";
@import "reg/profile";
@import "system/fancybox";
@import "podpiska/podpiska";
@import "system/cookies";

/*ANIMATE*/
@import "animate";

/*BOOTSTRAP*/
@import "bootstrap/bootstrap";