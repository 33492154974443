

.faq_gbook_block {
	margin-bottom: 20px;
	.input-textarea,
	textarea{
		width: 100%;
		min-height: 100px;
	}
}

.message_box{							/*отзывы о товаре*/
	margin: 0 0 10px;
	padding: 10px;
	border: 1px solid $borderColor;
	&:nth-child(2n){
		background: $moduleBg;
	}
}

.message_top {
	display: table;
	width: 100%;
	margin-bottom: 2px;
	border-bottom: inherit;
	padding: 2px 0;
	&._admin{
		.message_name{
			color: $linkColor;
		}
	}
}

.message_name, .message_date  {
	display: table-cell;
	vertical-align: top;
}

.message_name {
	font-weight: bold;
}

.message_date {
	width: 150px;
	font-weight: bold;
	text-align: right;
	padding: 0 5px;
}
.message_date > span{
	color: #999;
	font-style: italic;
	margin-right: 10px;
}

.message_text{
	color: inherit;
	padding: 5px;
}
