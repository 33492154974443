/* Раскладка */
html{
  &.section{
    .product{
      .product_photo{
        border: 1px solid $borderColor;
      }
    }
    .product_dop_photo_slider{
      .dop-photo__thumb{
        border: 1px solid $borderColor;
      }
    }
  }
}
.product {

  .product_left_block {
    float: left;
    position: relative;
    margin: 0 2em 0 0;
    width: calc(50% - 1em);
  }

  .product_mid_block {
    float: right;
    width: calc(50% - 1em);
  }


  @include media(md) {

    .product_left_block {
      margin: 0 1em 0 0;
      width: calc(50% - 0.5em);
    }

    .product_mid_block {
      width: calc(50% - 0.5em);
    }
  }

  @include media(sm) {

    .product_left_block {
      margin: 0 0 1em;
      width: 100%;
    }

    .product_mid_block {
      width: 100%;
    }
  }

}
  ._damped {
	background: #ffba6a;
	padding: 5px;
  }
  
  ._damped ._yours,
  ._damped .price,
  ._damped .currency {
	color: #fff;
  }

/* Оформление */
.product {
  margin: 2em 0;

  .product_top {
    margin: 0 0 3em;
  }


  .product-header {
    position: relative;
  }

  .prod_title {
    display: inline-block;
    color: $prodTitleColor;
    font-size: 18px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  .product_photo {
    & > a {
      display: block;
      outline: none;

      & > img {
        display: block;
        height: auto;
        max-width: 100%;
        width: 100%;
        border-radius: $borderRadius;
      }
    }
  }


  &.--hidden {
    display: none;
  }


  .compare {
    text-align: left;
  }


  .compare_box {
    margin: 0 4px 0 0;
    padding: 0;
  }


  .short_description {
    margin: 0.5em 0;

    * {
      font-size: 13px;
      padding: 0;
    }
    ul{
      list-style: none;
    }
  }

  .characters-table__row {
    color: $textColor;
    font-size: 14px;

    & > b {
      font-weight: normal;
    }
  }

	._chars_tab ._chars_item {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		border-bottom: 1px solid #ccc;
	}

	._chars_tab ._chars_item:last-child {
		border-bottom: 0px solid transparent;
	}

	._chars_tab ._chars_item:nth-child(odd){
		background: #fff;
	}

	._chars_tab ._chars_item  > div:nth-child(1) {
		border-right: 1px solid #ccc;
	}

	._chars_tab ._chars_item  > div {
		width: 50%;
		padding: 5px;	
	}

  .characters-table {

    .characters-table__row {
      @extend .clearfix;

      color: $textColor;
      line-height: 1.75;
      position: relative;
      z-index: 1;

      a {
        color: $linkColor;

        &:hover {
          color: $linkColor--hover;
        }
      }

      & > b {
        float: left;
        padding: 0 1em 0 0;

        &:before {
          border-bottom: 1px dashed $borderColor;
          content: '';
          display: block;
          position: absolute;
          height: 1px;
          top: 62%;
          left: 0;
          right: 0;
          z-index: -1;
        }
      }

      & > span {
        float: right;
        padding: 0 0 0 1em;
      }


      & > span,
      & > b {
        background: #f5f5f5;
        line-height: 1.75;
        z-index: 10;
      }

      & > b {
        font-weight: normal;
      }
    }
  }

  .prod_dop_option {

    & > b {
      font-weight: normal;
    }

    &.rating_block {

      color: $grey;

      .rate_results {
        color: $textColor;
        font-size: 13px;
        float: right;
        padding: 5px 0 5px 10px;
      }

      & > b {
        color: $textColor;
        font-weight: normal;
        line-height: 1.75;
        position: relative;
        z-index: 10;
      }
    }
  }



  .product-prod_prices {
    margin: 0.5em 0 0;
  }

  .prod_big_price {
    display: inline-block;
    margin: 0 1em 0 0;

    & > span {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .prod_price_old {
    display: inline-block;

    & > span {
      color: $grey;
      font-size: 14px;
      text-decoration: line-through;
    }
  }


  @include media(md) {
    padding: 1em 0 0;
  }
}


.kolvo_buy_button_add {
  margin: 1em 0 0;

  @extend .clearfix;

  .kolvo {
    float: left;
    margin: 0 1em 0 0;
    padding: 3px 0;
    width: 92px;
    user-select: none;
    border: 1px solid $linkColor--hover;
    background: $white;
    border-radius: $borderRadius;

    .input-text {
      display: inline-block;
      padding: 3px 0.25em 3px;
      text-align: center;
      vertical-align: middle;
      width: 34px;
      border-color: $linkColor--hover;
    }
  }

  .btn_add {
    float: left;
  }

  .kolvo_arrow {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    height: 24px;
    width: 24px;

    &:hover {
      background: $white;
      transition: background 0.3s ease;
    }
  }
}

.fav-link {
  display: inline-block;
  overflow: hidden;
  position: relative;
  height: 32px;
  width: 32px;
  text-indent: -9999px;
  vertical-align: text-bottom;

  @include fontawesome('\f004') {
    color: $textColor;
    text-indent: 0;
  }

  &.added {
    &:before {
      color: #d91c6b;
    }
  }

  &:hover {
    &:before {
      color: #d91c6b;
      transition: color 0.2s ease;
    }
  }
}



/* Ярлыки новинки/хит/скидка */
.product-labels {
  position: absolute;
  top: 1em;
  right: 1px;
  z-index: 1;

  & > span {
    color: #fff;
    cursor: default;
    display: block;
    font-size: 15px;
    margin: 0 0 4px;
    padding: 2px 6px;
    position: relative;
    height: 26px;
    text-align: center;
    user-select: none;
    &:hover{
      cursor: pointer;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 13px solid transparent;
      position: absolute;
      top: 0;
      left: -11px;
      bottom: 0;
    }
  }

  .label-new {
    background: #e54239;

    &:before {
      border-right-color: #e54239;
    }
  }

  .label-spec {
    background: #f19000;

    &:before {
      border-right-color: #f19000;
    }
  }

  .label-hit {
    background: #ffcd1f;

    &:before {
      border-right-color: #ffcd1f;
    }
  }
}

.product-labels {
  &.round-labels{
    > span{
      font-size: 12px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      padding: 0;
      line-height: 30px;
      &::before{
        display: none;
      }
    }
  }
}

.product_dop_photo_slider {

  .dop-photo__thumb {
    border-radius: $borderRadius;
    cursor: pointer;
    overflow: hidden;

    & > a {
      display: block;
    }

    & > img {
      display: block;
      width: 100%;
    }
  }


  & > .owl-carousel {
    padding: 0 1.5em;
    margin: 1em 0 0;


    & > .owl-stage-outer {
      padding: 1px 0;
    }


    & > .owl-nav {
      perspective-origin: center;

      .owl-prev,
      .owl-next {
        color: $linkColor;
        font-size: 3em;
        height: 46px;
        opacity: 1;
        padding: 0;
        position: absolute;
        width: 15px;
        top: 0;

        &:hover {
          opacity: 0.5;
          transition: opacity 0.2s ease;
        }
      }

      .owl-prev {
        left: 0;
      }

      .owl-next {
        right: 0;
      }
    }
  }
}

/*ВКЛАДКИ*/
.product_dop_modes {
  position: relative;
  font-size: 14px;
  margin: 1em 0 2em;

  .product_dop_modes_navigation {
    padding: 0;
    position: relative;
    height: 34px;
    z-index: 12;

    & > li {
      position: relative;
      display: block;
      float: left;
      font-size: 17px;
      color: $textColor;
      font-style: normal;
      height: 35px;
      line-height: 34px;
      padding: 0 20px;
      margin-right: 5px;
      cursor: pointer;
      border-radius: $borderRadius $borderRadius 0 0;

      &:hover {
        color: $white;
        background: $linkColor--hover;
        transition: background 0.3s ease;
      }

      &.mod_selected {
        cursor: default;
        color: #fff;
        background: $linkColor;
      }
    }
  }

  .product_dop_modes_content {
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    position: relative;
    z-index: 10;
    padding: 1em;
    text-align: justify;
    margin: 0 0 1em;
    border-radius: $borderRadius;

    li,
    p {
    }

    a {
      font-size: 14px;
    }

    .product_dop_title {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 1em;
    }
  }

}


/* Отзывы продукта */
.product-comments-form {
  @extend .clearfix;

  float: left;
  max-width: 300px;
  width: 100%;

  .form_title {
    position: static;
  }

  .form_block {
    margin: 0 0 1em;
  }

  .input-textarea,
  .input-text {
    display: block;
    padding: 3px 0.5em 3px;
    width: 100%;
  }

  .input-textarea {
    resize: none;
    height: 7em;
  }
}


.fast_order_form--bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9990;
  display: none;
}


.prod-bnts {
  float: right;
  width: 40%;
}


.prod_selector {
  float: left;
  margin: 0 0 1.5em;
  width: 60%;

  .prod_selector_wrapper {
    display: inline-block;
    position: relative;

    &:after {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: calc(1em + 3px);
      transform: rotate(-40deg);
    }

    &:before {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: 1em;
      transform: rotate(40deg);
    }
  }

  .select_size {
    border: 1px solid #a8a8a8;
    border-radius: 0;
    color: #a8a8a8;
    cursor: pointer;
    min-width: 228px;
    outline: none;
    padding: 3px 1em;
    height: 33px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none !important;
  }


  .prod_selector_title {
    display: inline-block;
    color: #696969;
    font: normal 15px/1 "Arial", "Helvetica", sans-serif;
    margin: 0 5% 0 0;
  }
}

select::-ms-expand {
  display: none;
}


.prod_selector_title {
  color: $grey;
  font-style: normal;
  font-size: 17px;
}


.prod-color-wrapper {
  display: inline-block;
  margin: 0.5em 0 0.5em;

  .prod-color-title {
    display: block;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    text-transform: lowercase;
  }
}


.responses_content {
  @extend .clearfix;

  .message_box {
    float: right;
    width: calc(100% - 300px);
    padding: 0 0 1em 1em;
    margin: 0 0 2em;
  }


  .message_top {
    border-bottom: 1px solid $borderColor;
    display: table;
    width: 100%;
    padding: 0 0 1em;
  }

  .message_text {
    font-size: 13px;
    margin: 0.5em 0 0;
  }


  .message_date,
  .message_name {
    display: table-cell;
  }


  .message_name {
    vertical-align: middle;

    & > span {
      font-size: 16px;
      font-weight: bold;
      vertical-align: middle;
    }
  }


  .message_date {
    float: right;
    text-align: center;

    & > span {
      font-size: 12px;
      padding: 1px 3px 2px;

      &.dt {
        background: $linkColor;
        color: #fff;
        display: block;
        border-radius: $borderRadius;
      }
    }
  }


  @include media(md) {

    .message_box {
      float: none;
      width: 100%;
    }
  }
}

.recommended-products,
.last-viewed-products{
  .short_description{
    display: none;
  }
}