.index{
	&.desktop{
		&.video_on{
			nav{
				.menu_level_1{
					padding: 0;
				}
				.search{
					padding: 0 0 0 1em;
				}
			}
		}
	}
}

nav {
	@include navtop();
	width: 100%;
	position: relative;
	z-index: 2;

	.menu_level_1 {
		display: table;
		list-style: none;
		width: 100%;
		@include media(md) {
			display: block;
			text-align: center;
		}

		& > li {
			display: table-cell;
			position: relative;
			vertical-align: top;
			@include media(md) {
				display: inline-block;
			}
			& > a {
				color: $white;
				display: block;
				padding: 1em 0.5em;
				text-transform: uppercase;
				text-decoration: none;
				text-align: center;
				@include media(md) {
					padding: 0.5em;
				}
			}
			&.selected {
				cursor: default;
				// background: $linkColor;
				& > a {
					// color: $white;
				}
				&:nth-child(1){
					background: linear-gradient(to top, #438DC8 - 30%, #438DC8);
				}
				&:nth-child(2){
					background: linear-gradient(to top, #f6b800 - 30%, #f6b800);
				}
				&:nth-child(3){
					background: linear-gradient(to top, #8870E6 - 30%, #8870E6);
				}
				&:nth-child(4){
					background: linear-gradient(to top, #e7792e - 30%, #e7792e);
				}
				&:nth-child(5){
					background: linear-gradient(to top, #ee60a8 - 30%, #ee60a8);
				}
				&:nth-child(6){
					background: linear-gradient(to top, #73B629 - 30%, #73B629);
				}
				&:nth-child(7){
					background: linear-gradient(to top, #438DC8 - 30%, #438DC8);
				}
				&:nth-child(8){
					background: linear-gradient(to top, #f6b800 - 30%, #f6b800);
				}
				&:nth-child(9){
					background: linear-gradient(to top, #8870E6 - 30%, #8870E6);
				}
				&:nth-child(10){
					background: linear-gradient(to top, #e7792e - 30%, #e7792e);
				}
				&:nth-child(11){
					background: linear-gradient(to top, #ee60a8 - 30%, #ee60a8);
				}
			}
			&:nth-child(1){
				background: linear-gradient(to top, #438DC8, #438DC8 + 30%);
			}
			&:nth-child(2){
				background: linear-gradient(to top, #f6b800, #f6b800 + 30%);
			}
			&:nth-child(3){
				background: linear-gradient(to top, #8870E6, #8870E6 + 30%);
			}
			&:nth-child(4){
				background: linear-gradient(to top, #e7792e, #e7792e + 30%);
			}
			&:nth-child(5){
				background: linear-gradient(to top, #ee60a8, #ee60a8 + 30%);
			}
			&:nth-child(6){
				background: linear-gradient(to top, #73B629, #73B629 + 30%);
			}
			&:nth-child(7){
				background: linear-gradient(to top, #438DC8, #438DC8 + 30%);
			}
			&:nth-child(8){
				background: linear-gradient(to top, #f6b800, #f6b800 + 30%);
			}
			&:nth-child(9){
				background: linear-gradient(to top, #8870E6, #8870E6 + 30%);
			}
			&:nth-child(10){
				background: linear-gradient(to top, #e7792e, #e7792e + 30%);
			}
			&:nth-child(11){
				background: linear-gradient(to top, #ee60a8, #ee60a8 + 30%);
			}
		}
		a {
			display: block;
			text-decoration: none;
		}


		&.deep-3,
		&.deep-2 {
			& > li {
				&.selected {
					& > a {

						&:hover {
						}
					}
				}
			}
		}


		&.deep-3 {
			.menu_level_2 {
				& > li {
					&.selected {
						& > a {

							&:hover {
							}
						}
					}
				}
			}
		}

	}

	/* Всплывающее меню */
	.menu_level_2 {
		background: #fff;
		box-shadow: none;
		display: table;
		list-style: none;
		margin: -1px 0 0;
		min-width: 150px;
		padding: 0;
		position: absolute;
		opacity: 0;
		top: 100%;
		left: 0;
		z-index: 999;
		text-align: left;
		text-transform: none;
		visibility: hidden;
		border-radius: 0 0 $borderRadius $borderRadius;

		& > li {
			display: table-cell;
			padding: 0.5em 1em;

			.menu-item__photo {
				float: left;
				width: 50%;

				& > img {
					display: block;
					height: auto;
					max-width: 100px;
					width: 100%;
				}
			}

			.menu-item__description {
				float: right;
				width: 50%;

				& > span {
					font-size: 12px;
				}
			}

			.menu-item__header {

				& > span {
					color: $textColor + 50%;
					font-size: 12px;
					vertical-align: top;
				}
			}

			& > a {
				color: $textColor;
				display: block;
				white-space: nowrap;
			}
			ul {
				display: block;
				list-style: none;
				padding: 0.5em 0.5em 0 1em;

				& > li {
					& > a {
						color: $textColor;
						display: block;
						white-space: nowrap;
						&:hover{
							color: $linkColor--hover;
						}
					}

					&.selected {
						&:before{
							color: $linkColor--hover;
						}
						& > a {
							color: $linkColor--hover;
						}
					}
				}
			}
		}
	}
}

.desktop {
	nav {
		.menu_level_1 {
			position: relative;
			width: 100%;
			& > li {
				/* Показываем всплывашку на ховер */
				&.subcat {
					&:hover {
						.menu_level_2 {
							opacity: 1;
							transition: opacity 0.3s ease, transform 0.3s ease-out;
							visibility: visible;
							transform: translate3d(0, 0, 0);
							box-shadow: $module_top__box-shadow;
							border: 1px solid $borderColor;
						}
					}
				}
				&:not(.selected) {
					&:nth-child(1){
						&:hover{
							background: linear-gradient(to top, #438DC8 + 30%, #438DC8 + 60%);
						}
					}
					&:nth-child(2){
						&:hover{
							background: linear-gradient(to top, #f6b800 + 30%, #f6b800 + 60%);
						}
					}
					&:nth-child(3){
						&:hover{
							background: linear-gradient(to top, #8870E6 + 30%, #8870E6 + 60%);
						}
					}
					&:nth-child(4){
						&:hover{
							background: linear-gradient(to top, #e7792e + 30%, #e7792e + 60%);
						}
					}
					&:nth-child(5){
						&:hover{
							background: linear-gradient(to top, #ee60a8 + 30%, #ee60a8 + 60%);
						}
					}
					&:nth-child(6){
						&:hover{
							background: linear-gradient(to top, #73B629 + 30%, #73B629 + 60%);
						}
					}
					&:nth-child(7){
						&:hover{
							background: linear-gradient(to top, #438DC8 + 30%, #438DC8 + 60%);
						}
					}
					&:nth-child(8){
						&:hover{
							background: linear-gradient(to top, #f6b800 + 30%, #f6b800 + 60%);
						}
					}
					&:nth-child(9){
						&:hover{
							background: linear-gradient(to top, #8870E6 + 30%, #8870E6 + 60%);
						}
					}
					&:nth-child(10){
						&:hover{
							background: linear-gradient(to top, #e7792e + 30%, #e7792e + 60%);
						}
					}
					&:nth-child(11){
						&:hover{
							background: linear-gradient(to top, #ee60a8 + 30%, #ee60a8 + 60%);
						}
					}
				}
			}
		}


		.menu_level_2 {
			display: table-row;
			left: 0;
			transform-origin: center;
			transform: translate3d(0, 1em, 0);

			& > li {
				float: left;
				width: 100%;

				&.selected {
					& > a {
						color: $linkColor--hover;
					}
				}

				&:not(.selected) {
					& > a {
						&:hover {
							color: $linkColor--hover;
						}
					}
				}
			}
		}

		.menu_level_3 {
			& > li {
				position: relative;
				font-size: 13px;
				@include fontawesome('\f105') {
					font-size: 14px;
					color: $textColor;
					left: 0;
				}
				&:not(.selected) {
					&:hover {
						&:before{
							color: $linkColor--hover;
							transition: color 0.3s ease;
						}
						& > a {
							color: $linkColor--hover;
						}
					}
				}
			}
		}

		.search {
			float: right;
			padding: 0 1em;
			width: 250px;
			background: linear-gradient(to top, #0571b9, #4eb8ff);
			@include media(lg) {
				margin: 0;
				width: 100%;
			}
		}

		.menu-wrapper {
			float: left;
			width: calc(100% - 250px);
			@include media(lg) {
				width: 100%;
			}
		}
	}
}

